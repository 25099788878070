import React from 'react';
import { Col } from 'react-bootstrap';
import styles from './ProjectCard.module.css'; // Ensure the path is correct

const inDevelopmentStyle = {
  fontFamily: 'Andy',
  color: '#ffff00',
  fontSize: '2rem',
  fontStyle: 'normal'
};

export const ProjectCard = ({ title, description, imgUrl, githubUrl, developmentState, tools }) => {
  return (
    <Col xs={12} sm={6} md={4}>
      <div className={styles.projImgBx}>
        <img src={imgUrl} alt={title} />
        <div className={styles.projTxtx}>
          <h4>{title}</h4>

          {/* Only show the description, tools, and GitHub link if the project is not in development */}
          {!developmentState && (
            <>
              <span>{description}</span>
              <div className={styles.tools}>
                {tools && tools.map((tool, index) => (
                  <span key={index} className={styles.toolTag}>{tool}</span>
                ))}
              </div>
              {githubUrl && (
                <a href={githubUrl} target="_blank" rel="noopener noreferrer" className={styles.githubLink}>View on GitHub</a>
              )}
            </>
          )}

          {/* Show "Currently In Development" if developmentState is true */}
          {developmentState && (
            <span style={inDevelopmentStyle}>Currently In Development!</span>
          )}
        </div>
      </div>
    </Col>
  );
};
