import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import LinkedIn from "../assets/img/LinkedIn.svg";
import GitHubIco from "../assets/img/GitHub.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon mt-5">
              <a href="https://www.linkedin.com/in/daudsk/"><img src={LinkedIn} alt="Icon" /></a>
              <a href="https://github.com/daudsk"><img src={GitHubIco} alt="Icon" /></a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
