import React from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import Reversi from '../assets/img/flipmulch.svg';
import DiscordBot from '../assets/img/DiscordCustomBot.png';
import IconGen from '../assets/img/icongen.png';
import ecommerce from '../assets/img/e-commerce-project.png'
import colorSharp2 from '../assets/img/color-sharp2.png';
import WeatherForecast from '../assets/img/forecast.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import styles from './Projects.module.css'

const cardStyle = {
  height: '200px', // Fixed height for images
  objectFit: 'cover', // Ensures images cover the area without stretching
  width: '100%' // Ensures image takes full width of the card
};

export const Projects = () => {

  const projects = [
    {
      title: "Discord Bot",
      description: "Developed with Node.js and Discord.js, featuring custom command handling and integration with MongoDB for data persistence.",
      imgUrl: DiscordBot,
      githubUrl: "https://github.com/daudsk",
      tools: ["JavaScript", "NodeJS", "MongoDB"],
      developmentState: false,
      status: 'active', // Example status
    },
    {
      title: "Icon Generator Using AI",
      description: "Combines AI and creativity for generating unique icons.",
      imgUrl: IconGen,
      tools: ['NextJS', 'NextAuthJS', 'Prisma', 'Tailwind CSS', 'tRPC'],
      developmentState: true,
      status: 'inProgress', // Example status
    },
    {
      title: "Reversi",
      description: "A Multi-Player Game Coming Soon",
      imgUrl: Reversi,
      developmentState: true,
      tools: ['React', 'Bootstrap'],
      status: 'inProgress', // Example status
    },
    {
      title: "E-commerce Shop Online",
      description: "Combines AI and creativity for generating unique icons.",
      imgUrl: ecommerce,
      tools: ['Node JS', 'GraphQL', 'MongoDB', 'Deno', 'Rest API'],
      developmentState: true,
      status: 'inProgress', // Example status
    },
    {
      title: "Weather Forecast",
      description: "A previous achievement and collaboration with my peer colleague!",
      imgUrl: WeatherForecast,
      githubUrl: "https://github.com/carlandreea/WEATHER-FORECAST-APP/",
      developmentState: false,
      tools: ['Vanilla JS','HTML/CSS', 'Collaboration'],
      status: 'completed', // Example status
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2 className={styles.projectHeader}>Projects</h2>
                  <p className={styles.projectDescription}>Explore my portfolio to discover a variety of software development and design projects, showcasing creativity and technical expertise.</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">                      <Nav.Item>
                        <Nav.Link eventKey="first">Active Projects</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">In Progress</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Completed</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.filter(project => project.status === "active").map((project, index) => (
                            <ProjectCard key={index} {...project} cardStyle={cardStyle} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projects.filter(project => project.status === "inProgress").map((project, index) => (
                            <ProjectCard key={index} {...project} cardStyle={cardStyle} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {projects.filter(project => project.status === "completed").map((project, index) => (
                            <ProjectCard key={index} {...project} cardStyle={cardStyle} />
                          ))}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />
    </section>
  );
};
