import React from "react";

import styles from "./Skills.module.css"

import JavaScript from "../assets/img/javascript.svg";
import PHPIcon from "../assets/img/PHP.svg";
import XMLIcon from "../assets/img/XML.svg";
import Java from "../assets/img/java.svg";
import ActionScript from "../assets/img/actionscript.svg";
import mySQLIcon from "../assets/img/MySQL.svg";
import posteGreSql from "../assets/img/PosteGreSQL.svg";
import mongoDB from "../assets/img/MongoDB.svg";
import Python from "../assets/img/python.svg";
import C from "../assets/img/C.svg"
import CPlus from "../assets/img/cplusplus.svg";
import Csharp from "../assets/img/Csharp.svg";
import ReactIcon from "../assets/img/React.svg";
import TypeScript from "../assets/img/typescript.svg";
import ReduxIcon from "../assets/img/redux.svg";
import Git from "../assets/img/git.svg";
import DockerIcon from "../assets/img/Docker.svg";
import HtmlIcon from "../assets/img/html.svg";
import CSSIcon from "../assets/img/CSS.svg";
import SassIcon from "../assets/img/sass.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Tools I'm pretty accustomed to using</h2>
              <p>These are tools, I juggle around with and I am very adaptable with, whenever I need to!</p>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={JavaScript} title="JavaScript" alt="JavaScript" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={PHPIcon} title="PHP" alt="PHP" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={XMLIcon} title="XML" alt="XML" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={ActionScript} title="ActionScript" alt="ActionScript" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={Java} title="Java" alt="Java" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={mySQLIcon} title="MySQL" alt="MySQL" />
                    {/* <p></p> */}
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={posteGreSql} title="PostegreSQL" alt="PostegreSQL" />
                    {/* <p></p> */}
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={mongoDB} title="MongoDB" alt="MongoDB" />
                    {/* <p></p> */}
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={Python} title="Python" alt="Python" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={C} title="C" alt="C" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={CPlus} title="C++" alt="C++" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={Csharp} title="C#" alt="C#" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={ReactIcon} title="React" alt="React" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={ReduxIcon} title="Redux" alt="Redux" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={TypeScript} title="TypeScript"alt="TypeScript" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={Git} title="Git" alt="Git" />
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={DockerIcon} title="Docker" alt="Docker" />
                    {/* <p></p> */}
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={HtmlIcon} title="HTML5" alt="HTML5" />
                    {/* <p></p> */}
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={CSSIcon} title="CSS3" alt="CSS3" />
                    {/* <p></p> */}
                  </div>
                </div>
                <div className="item">
                  <div className="skill-icon d-flex justify-content-center">
                    <img src={SassIcon} title="Sass" alt="Sass" />
                    {/* <p></p> */}
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="skill-bx">
              {/* <h3>Test</h3> */}
            </div>
          </div>

        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
